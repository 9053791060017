<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Merchant"
            label-for="merchant"
          >
            <v-select
              v-model="filters['merchant_id']"
              :options="merchantOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Merchant"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Rider"
            label-for="rider"
          >
            <v-select
              v-model="filters['rider']"
              :options="riderOptions"
              :reduce="option => option.id"
              label="first_name"
              placeholder="Select Rider"
              @input="onClickDeselectAll()"
            >
              <template v-slot:option="option">
                {{ option.first_name }} {{ option.last_name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedCard !== 'allOrders' && selectedCard !=='pendingDelivery'"
          cols="12"
          md="3"
        >
          <b-form-group
            label="Delivery Date"
            label-for="delivery-date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['status_date']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedCard == 'pendingDeposit' || selectedCard == 'deposited' || selectedCard == 'approved'"
          cols="12"
          md="3"
        >
          <b-form-group
            :label="selectedCard == 'pendingDeposit'?'Deposit Create Date' : selectedCard == 'deposited'? 'Deposited Date':'Approved Date'"
            label-for="finance-status-date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['staff_deposit_status_date']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['staff_deposit_status_date']"
                class="flatpickr-cancel"
                @click="clearDate('staff_deposit_status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <div>
      <b-row class="match-height">
        <b-col md="4">
          <overview-card
            title="All Orders"
            :subtitle="allOrderStats.count !== undefined && allOrderStats.count !== null ? allOrderStats.count + ' Orders' : 'Loading...'"
            :sum="(allOrderStats.sum)"
            :border-variant="selectedCard == 'allOrders' ? 'primary' : ''"
            tooltip-description="All assigned order to the rider."
            icon="MenuIcon"
            indicator-color="#67b7b7"
            @click.native="onCardSelect('allOrders','','staff_deposit_id')"
          />
        </b-col>
        <b-col md="4">
          <overview-card
            title="Pending Delivery"
            :subtitle="pendingNotDeliverdStats.count !== undefined && pendingNotDeliverdStats.count !== null ? pendingNotDeliverdStats.count + ' Orders' : 'Loading...'"
            :sum="(pendingNotDeliverdStats.sum)"
            :border-variant="selectedCard == 'pendingDelivery' ? 'primary' : ''"
            tooltip-description="All assigned order to the rider but didn\'t create any deposit note yet and NOT DELIVERED."
            icon="RepeatIcon"
            indicator-color="#6a76b0"
            @click.native="onCardSelect('pendingDelivery','&filter[status]=key_5,key_6,key_12','staff_deposit_id')"
          />
        </b-col>
        <b-col md="4">
          <overview-card
            title="With Drivers"
            :subtitle="pendingDeliverdStats.count !== undefined && pendingDeliverdStats.count !== null ? pendingDeliverdStats.count + ' Orders' : 'Loading...'"
            :sum="(pendingDeliverdStats.sum)"
            :border-variant="selectedCard == 'withDrivers' ? 'primary' : ''"
            tooltip-description="All assigned order to the rider but didn't create any deposit note yet."
            icon="TruckIcon"
            indicator-color="#b7a164"
            @click.native="onCardSelect('withDrivers','null&filter[status]=key_7,key_13,key_14','staff_deposit_id')"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="3">
          <overview-card
            title="Rider Commission"
            :subtitle="totalRiderCommisionStats.count !== undefined && totalRiderCommisionStats.count !== null ? totalRiderCommisionStats.count + ' Orders' : 'Loading...'"
            :sum=" (totalRiderCommisionStats.sum)"
            :border-variant="selectedCard == 'riderCommission' ? 'primary' : ''"
            tooltip-description="Total rider commission for all orders for which a rider deposit note has been created."
            icon="DollarSignIcon"
            indicator-color="#10882b"
            @click.native="onCardSelect('riderCommission','true&filter[rider_commission]=true','staff_deposit_id')"
          />
        </b-col>
        <b-col md="3">
          <overview-card
            title="Pending Deposit"
            :subtitle="pendingDepositStats.count !== undefined && pendingDepositStats.count !== null ? pendingDepositStats.count + ' Orders' : 'Loading...'"
            :sum=" (pendingDepositStats.sum)"
            :border-variant="selectedCard == 'pendingDeposit' ? 'primary' : ''"
            tooltip-description="Total amount of orders in the deposit notes, which is in the PENDING status of the deposit note."
            icon="RefreshCwIcon"
            indicator-color="#adb65c"
            @click.native="onCardSelect('pendingDeposit','&filter[staff_deposit]=f_key_1','staff_deposit_id')"
          />
        </b-col>
        <b-col md="3">
          <overview-card
            title="Deposited"
            :subtitle="depositedStats.count !== undefined && depositedStats.count !== null ? depositedStats.count + ' Orders' : 'Loading...'"
            :sum="(depositedStats.sum)"
            :border-variant="selectedCard == 'deposited' ? 'primary' : ''"
            tooltip-description="Total amount of orders in the deposit notes, which is in the DEPOSITED status of the deposit note."
            icon="CheckIcon"
            indicator-color="#70af51"
            @click.native="onCardSelect('deposited','&filter[staff_deposit]=f_key_2','staff_deposit_id')"
          />
        </b-col>
        <b-col md="3">
          <overview-card
            title="Approved"
            :subtitle="approvedStats.count !== undefined && approvedStats.count !== null ? approvedStats.count + ' Orders' : 'Loading...'"
            :sum="(approvedStats.sum)"
            :border-variant="selectedCard == 'approved' ? 'primary' : ''"
            tooltip-description="Total amount of orders in the deposit notes, which is in the APPROVED status of the deposit note."
            icon="CheckCircleIcon"
            indicator-color="#b78164"
            @click.native="onCardSelect('approved','&filter[staff_deposit]=f_key_3','staff_deposit_id')"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row class="d-sm-flex align-items-start">
              <b-col
                md="12"
                xl="8"
                class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
              >
                <div
                  v-if="selectedCard == 'withDrivers'"
                >
                  <b-button
                    v-can="'rider-finance-create'"
                    :disabled="selectedWaybillArray.length === 0"
                    class="mt-1"
                    variant="success"
                    @click="onClickGenerateDeposit()"
                  >
                    Generate Deposit
                  </b-button>
                </div>
                <div
                  v-if="depositData"
                  class="d-flex mt-1 flex-wrap"
                >
                  <span class="ml-md-2 pr-1 font-weight-bolder">
                    Collected COD:
                  </span>
                  <span>{{ __numberWithCommas(depositData.total_collected_cod, true) }}</span>
                  <span class="ml-1 font-weight-bolder">
                    |
                  </span>

                  <span class="ml-1 pr-1 font-weight-bolder">
                    Commission:
                  </span>
                  <span>{{ __numberWithCommas(depositData.rider_commission, true) }}</span>

                  <span class="ml-1 font-weight-bolder">
                    |
                  </span>
                  <span class="ml-1 pr-1 font-weight-bolder">
                    NET Payable:
                  </span>
                  <span>{{ __numberWithCommas(depositData.net_payable, true) }}</span>

                </div>
              </b-col>
              <b-col
                class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
              >
                <ReportTableButtons
                  :items="rows"
                  :json_fieldz="json_fields"
                  :name="`RiderOverview - Page ${page}.xls`"
                  :bulkname="`RiderOverview.xls`"
                  :fetch="fetchOrderListNoPagination"
                  @refresh="onClickRefresh"
                />
              </b-col>
            </b-row>
            <b-row v-if="selectedCard == 'withDrivers'">
              <div class="font-weight-bolder text-muted mt-1 ml-1">
                {{ `${selectedWaybillArray.length} record${selectedWaybillArray.length === 1 ? '':'s'}` }} selected
              </div>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            variant="light"
            opacity="0.30"
            blur="10px"
            rounded="sm"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="selectedCard == 'withDrivers'?fields:fields.slice(1,fields.length)"
              show-empty
              sticky-header="100vh"
              class="hide-vertical-scroll"
              @sort-changed="sortChanged"
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template
                v-if="selectedCard == 'withDrivers'"
                #head(id)
              >
                <div class="d-flex align-items-start">
                  <div>
                    <b-form-checkbox
                      id="selectAllCheckbox"
                      v-model="selectAllCheckbox"
                      name="selectAllCheckbox"
                      @change="onClickSelectAllCheckbox()"
                    />
                  </div>
                </div>
              </template>
              <template
                v-if="selectedCard == 'withDrivers'"
                #cell(id)="data"
              >
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedWaybillArray"
                        :value="data.item.waybill_number"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(waybill_number)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.customer_name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                      @click="onClickViewOrder(data.item.id)"
                    >
                      {{ data.value }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(merchant.name)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="">
                      {{ `${data.value}${data.item.walking_agency_deposit_id || data.item.walking_branch_deposit_id ? '&nbsp;[&nbsp;WC&nbsp;]' : ''}` }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.customer_name }}
                    </div>
                    <div class="font-small-2">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                    <div class="font-small-2">
                      {{ data.item.customer_address }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(rider_name)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.rider ? `${data.item.rider.first_name} ${data.item.rider.last_name}` : '---' }}
                    </div>
                    <div class="font-small-2">
                      {{ data.item.rider ? data.item.rider.email : '' }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(rider_deposit)="data">
                <div
                  class="d-flex justify-content-center font-weight-bolder"
                  style="margin-bottom:2px;"
                >
                  {{ data.value.ref_no || '---' }}
                </div>
                <div class="d-flex justify-content-center">
                  <finance-status-badge
                    :status-key="data.item.rider_deposit_status? data.item.rider_deposit_status.name : ''"
                    :finance-status="financeStatusOptions"
                  />
                </div>
              </template>
              <template #cell(cod)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(collected_cod)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(rider_commission)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(depositable_amount)="data">
                <span>{{ __numberWithCommas(data.value, true) }}</span>
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(order_current_status.key)="data">
                <order-status-badge :status-key="data.value" />
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-can="'show-orders'"
                    @click="onClickViewOrder(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.rider_signature"
                    :href="`${bucketUrl}${data.item.rider_signature}`"
                    target="_blank"
                  >
                    <feather-icon icon="Edit2Icon" />
                    View Receiver's Signature
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row
                cols="12"
              >
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block width-100"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  md="9"
                >
                  <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
    <deposit-detail-modal ref="deposit_detail_modal" />
    <make-remark ref="add_make_remark" />
    <make-future-request ref="add_future_request" />
    <flag-details ref="flag_detail" />
    <view-order-modal ref="viewOrderModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { bucketUrl } from '@/constants/config'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ViewOrderModal from '@/components/AllOrders/ViewOrderModal.vue'
import MakeRemark from '@/components/AllOrders/Remark/MakeRemark.vue'
import FlagDetails from '@/components/AllOrders/FlagDetails.vue'
import MakeFutureRequest from '@/components/AllOrders/MakeFutureRequest.vue'
import DepositDetailModal from '@/components/FinanceModuleForms/InfoModals/DepositDetailModal.vue'
import OverviewCard from '@core/components/overview-card/OverviewCard.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const OrderRepository = RepositoryFactory.get('order')
const FinanceRepository = RepositoryFactory.get('finance')
const merchantRepository = RepositoryFactory.get('merchant')
const riderRepository = RepositoryFactory.get('rider')
const OverviewDashboardRepository = RepositoryFactory.get('overviewdashboard')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BButton,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    vSelect,
    OrderStatusBadge,
    FinanceStatusBadge,
    OverviewCard,
    BDropdown,
    BDropdownItem,
    MakeRemark,
    MakeFutureRequest,
    FlagDetails,
    flatPickr,
    ViewOrderModal,
    DepositDetailModal,
    BCardActions,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      // Filters Start
      allOrderStats: { sum: null, count: undefined },
      totalRiderCommisionStats: { sum: null, count: undefined },
      pendingNotDeliverdStats: { sum: null, count: undefined },
      pendingDeliverdStats: { sum: null, count: undefined },
      pendingDepositStats: { sum: null, count: undefined },
      depositedStats: { sum: null, count: undefined },
      approvedStats: { sum: null, count: undefined },
      filters: {},
      statusOptions: [],
      financeStatusOptions: [],
      riderOptions: [],
      merchantOptions: [],
      finance: [],
      options: {
        delimiter: {
          delimiter: ' to ',
          blocks: [4, 4],
          uppercase: true,
        },
      },
      // Filters End
      avatarText,
      bucketUrl,
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filterName: '',
      fields: [
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'waybill_number',
          label: 'WayBill Number',
          sortable: true,
          thClass: 'text-left',
        },
        {
          key: 'merchant.name',
          label: 'Merchant',
          sortable: true,
          thClass: 'text-left',
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'customer_email',
          label: 'Customer',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'rider_name',
          label: 'Rider',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'collected_cod',
          label: 'Collected Cod',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rider_commission',
          label: 'Rider Commission',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'depositable_amount',
          label: 'Depositable Amount',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'rider_deposit',
          label: 'Rider Deposit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'order_current_status.key',
          label: 'Status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-center',
        },
      ],
      json_fields: {
        'Waybill Number': 'waybill_number',
        Merchant: {
          field: 'merchant.name',
          callback: value => `"${value || '---'}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value || '---'}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value || '---'}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value || '---'}"`, // Wrap the address in double quotes
        },
        'Customer Phone': {
          field: 'customer_phone',
          callback: value => `"${value || '---'}"`,
        },
        'Second Phone Number': {
          field: 'customer_secondary_phone',
          callback: value => `"${value || '---'}"`,
        },
        'Rider First Name': {
          field: 'rider.first_name',
          callback: value => `"${value || '---'}"`,
        },
        'Rider Last Name': {
          field: 'rider.last_name',
          callback: value => `"${value || '---'}"`,
        },
        'Rider Email': {
          field: 'rider.email',
          callback: value => `"${value || '---'}"`,
        },
        COD: 'cod',
        'Collected COD': 'collected_cod',
        'Rider Commission': 'rider_commission',
        'Depositable Amount': 'depositable_amount',
        'Rider Deposit': {
          field: 'rider_deposit',
          callback: value => `"${value.ref_no || '---'}"`,
        },
        Status: 'order_current_status.name',
      },
      rows: [],
      selectedWaybillArray: [],
      selectedCard: 'allOrders',
      selectedKey: '',
      attribute: '',
      depositData: null,
      selectAllCheckbox: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.fetchOrderList()
    },
    perPage() {
      this.fetchOrderList()
    },
    selectedWaybillArray: {
      async handler(val) {
        if (val.length > 0 && this.selectedCard === 'withDrivers') {
          await this.getSelectedOrderDetails()
        } else {
          this.depositData = null
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    await this.initPageData()
  },

  methods: {
    async initPageData() {
      try {
        await Promise.all([
          this.fetchOrderList(),
          this.readOrderStatuses(),
          this.financeStatusOptions = JSON.parse(localStorage.getItem('finance_statuses')),
          this.getMerchantList(),
          this.getRiderList(),
          this.readFinanceStatsData(),
        ])
        this.fetchOrderList()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async readFinanceStatsData() {
      try {
        this.setStatsLoadingState()
        this.readDataforAllOrderStats(this.filters)
        this.readDataforTotalRiderCommisionStats(this.filters)
        this.readDataforPendingNotDeliverdStats(this.filters)
        this.readDataforPendingDeliverdStats(this.filters)
        this.readDataforPendingDepositStats(this.filters)
        this.readDataforDepositedStats(this.filters)
        this.readDataforApprovedStats(this.filters)
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    setStatsLoadingState() {
      const statsObjects = [
        'allOrderStats',
        'totalRiderCommisionStats',
        'pendingNotDeliverdStats',
        'pendingDeliverdStats',
        'pendingDepositStats',
        'depositedStats',
        'approvedStats',
      ]
      statsObjects.forEach(stat => {
        this[stat].count = undefined
        this[stat].sum = null
      })
    },
    async readDataforAllOrderStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getAllOrdersStatsFinance(this.filterQuery))
        this.allOrderStats.sum = data.all_orders.total
        this.allOrderStats.count = data.all_orders.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforTotalRiderCommisionStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getTotalRiderCommisionStatsFinance(this.filterQuery))
        this.totalRiderCommisionStats.sum = data.total_rider_commision.sum
        this.totalRiderCommisionStats.count = data.total_rider_commision.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforPendingNotDeliverdStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getPendingNotDeliverdStatsFinance(this.filterQuery))
        this.pendingNotDeliverdStats.sum = data.pending_not_delivered.sum
        this.pendingNotDeliverdStats.count = data.pending_not_delivered.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforPendingDeliverdStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getPendingDeliverdStatsFinance(this.filterQuery))
        this.pendingDeliverdStats.sum = data.pending_delivered.sum
        this.pendingDeliverdStats.count = data.pending_delivered.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforPendingDepositStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getPendingDepositStatsFinance(this.filterQuery))
        this.pendingDepositStats.sum = data.pending_deposit.sum
        this.pendingDepositStats.count = data.pending_deposit.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforDepositedStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getDepositedStatsFinance(this.filterQuery))
        this.depositedStats.sum = data.deposited.sum
        this.depositedStats.count = data.deposited.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforApprovedStats() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await OverviewDashboardRepository.getApprovedStatsFinance(this.filterQuery))
        this.approvedStats.sum = data.approved.sum
        this.approvedStats.count = data.approved.count
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchOrderList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        if (this.selectedCard === 'allOrders') {
          const { data } = (await FinanceRepository.getRiderOverviewFinance(this.page, this.filterQuery, this.perPage))
          this.rows = data.data
          this.meta = data.meta
        } else {
          const { data } = (await FinanceRepository.getRiderOverviewFinanceByKey(this.page, this.attribute, this.selectedKey, this.filterQuery, this.perPage))
          this.rows = data.data
          this.meta = data.meta
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
      this.onClickDeselectAll()
    },
    async fetchOrderListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getRiderOverviewFinanceNoPagination(this.attribute, this.selectedKey, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onCardSelect(selectedCard, key, attribute, filters) {
      this.selectedKey = key
      this.selectedCard = selectedCard
      this.attribute = attribute
      if (filters) {
        this.filters = {
          status: [filters],
        }
      }
      if (this.selectedCard !== 'withDrivers') {
        this.depositData = null
      }
      this.fetchOrderList()
    },
    async readOrderStatuses() {
      this.statusOptions = localStorage.getItem('order_statuses') ? JSON.parse(localStorage.getItem('order_statuses')) : (await OrderRepository.getFullStatusList()).data.data
      if (!localStorage.getItem('order_statuses')) localStorage.setItem('order_statuses', JSON.stringify(this.statusOptions))
    },
    async getMerchantList() {
      this.merchantOptions = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await merchantRepository.getMerchantListForDropdown()).data.data
      if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchantOptions))
    },
    async getRiderList() {
      this.riderOptions = localStorage.getItem('rider_list') ? JSON.parse(localStorage.getItem('rider_list')) : (await riderRepository.getAllRiderDropDown()).data.data
      if (!localStorage.getItem('rider_list')) localStorage.setItem('rider_list', JSON.stringify(this.riderOptions))
    },
    async getSelectedOrderDetails() {
      const payload = {
        waybill_numbers: this.selectedWaybillArray,
      }
      const { data } = (await FinanceRepository.getRiderDepositDetailsBeforeSubmit(payload)).data
      this.depositData = data
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
    onClickSelectAll() {
      this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
    },
    onClickDeselectAll() {
      this.selectedWaybillArray = []
    },
    async onClickGenerateDeposit() {
      if (this.filters.rider) {
        if (this.filters.rider !== this.rows[0].rider.id) {
          this.showErrorMessage('Selected orders do not match the selected rider')
        } else {
          const depositDate = new Date().toISOString().slice(0, 10)
          const deposit = {
            waybill_numbers: this.selectedWaybillArray,
            deposit_date: depositDate,
            rider_id: this.filters.rider,
            expenses: [],
          }
          this.$refs.deposit_detail_modal.openModal(this.selectedWaybillArray, this.createDeposit, deposit, 'rider')
        }
      } else {
        this.showErrorMessage('Please filter out a rider first')
      }
    },
    async createDeposit(deposit) {
      const res = await FinanceRepository.createRiderFinanceDeposit(deposit)
      if (res.status === 201) {
        this.showSuccessMessage('Data Added Successfully')
        await this.$router.push('/operations/rider-finance')
      } else {
        this.showErrorMessage('Cannot add deposit')
      }
    },
    onClickPrintDispatch() {
      this.$router.push({
        name: 'print-pod',
        params: {
          selectedWaybills: this.selectedWaybillArray,
        },
      })
    },
    // REQUIRED
    applyFilters() {
      this.fetchOrderList()
      this.onClickDeselectAll()
      this.readFinanceStatsData()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchOrderList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.fetchOrderList()
    },
    changePage(value) {
      this.perPage = value
    },
    onWaybillSearch() {
      this.filterName = 'waybill_number'
      this.fetchOrderList()
    },
    onCustomerSearch() {
      this.filterName = 'customer_name'
      this.fetchOrderList()
    },
    onClickMakeRemark(id) {
      this.$refs.add_make_remark.openModal(id)
    },
    onClickFlagDetail(id) {
      this.$refs.flag_detail.openModal(id)
    },
    onClickMakeFutureRequest(id) {
      this.$refs.add_future_request.openModal(id)
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchOrderList()
      }
    },
    onClickRefresh() {
      this.fetchOrderList()
      this.readFinanceStatsData()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.selectedWaybillArray = this.rows.map(n => n.waybill_number)
      } else {
        this.selectedWaybillArray = []
      }
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
@import '../../../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
